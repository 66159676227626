<template>
  <!-- 表单样式  如果有温馨提示才加  with-warm-tip， 没有则不加 -->
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="广告名称" prop="name">
      <ykc-input v-model="ruleForm.name" maxlength="50" :placeholder="'请输入名称'"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="广告位置" prop="launchType">
      <ykc-dropdown
        placeholder="请选择广告位置"
        :clearable="false"
        :data="dictionary.launchType"
        v-model="ruleForm.launchType"></ykc-dropdown>
    </ykc-form-item>

    <ykc-form-item
      prop="image"
      label="广告图片"
      class="file-error img-error"
      bottomTip="温馨提示：请上传大小1M，尺寸750pxx1334px的jpg、jepg、png图片">
      <div class="flex upload-img-box">
        <ykc-upload ref="adsImg" @handleChange="uploadAd"></ykc-upload>
      </div>
    </ykc-form-item>

    <ykc-form-item prop="launchTerminalType" label="投放终端">
      <el-checkbox-group v-model="launchTerminalType2" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="item in launchTerminalType" :label="item.id" :key="item.id">
          {{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
    </ykc-form-item>

    <ykc-form-item
      label="客户端跳转"
      prop="appSkipType"
      v-if="ruleForm.launchTerminalType.indexOf('1') > -1 && ruleForm.launchType != 2">
      <ykc-radio :data="dictionary.skipType" v-model="ruleForm.appSkipType"></ykc-radio>
    </ykc-form-item>

    <ykc-form-item
      label="跳转地址"
      prop="appSkipUrl"
      v-if="ruleForm.appSkipType === '2' && ruleForm.launchType != 2">
      <ykc-input v-model="ruleForm.appSkipUrl" :placeholder="'请输入跳转地址'"></ykc-input>
    </ykc-form-item>

    <ykc-form-item
      label="微信跳转"
      prop="wechatSkipType"
      v-if="ruleForm.launchTerminalType.indexOf('2') > -1 && ruleForm.launchType != 2">
      <ykc-radio :data="dictionary.wechatSkipType" v-model="ruleForm.wechatSkipType"></ykc-radio>
    </ykc-form-item>

    <ykc-form-item
      label="链接地址"
      prop="wechatSkipUrl"
      v-if="ruleForm.wechatSkipType === '2' && ruleForm.launchType != 2">
      <ykc-input v-model="ruleForm.wechatSkipUrl" :placeholder="'请输入链接地址'"></ykc-input>
    </ykc-form-item>

    <ykc-form-item
      label="APP_编码"
      prop="wechatAppId"
      v-if="ruleForm.wechatSkipType === '3' && ruleForm.launchType != 2">
      <ykc-input v-model="ruleForm.wechatAppId" :placeholder="'请输入链接地址'"></ykc-input>
    </ykc-form-item>

    <ykc-form-item
      label="页面地址"
      prop="wechatSkipUrl"
      v-if="ruleForm.wechatSkipType === '3' && ruleForm.launchType != 2">
      <ykc-input v-model="ruleForm.wechatSkipUrl" :placeholder="'请输入链接地址'"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="投放日期" prop="launchTime">
      <ykc-date-picker
        limitDate="limitBefore"
        format="yyyy-MM-dd"
        @input="dateChange"
        v-model="launchTime"></ykc-date-picker>
    </ykc-form-item>

    <ykc-form-item label="用户维度" prop="userScopeType">
      <ykc-radio
        :data="dictionary.userScopeType"
        @change="groupChange"
        v-model="ruleForm.userScopeType"></ykc-radio>
    </ykc-form-item>

    <ykc-form-item prop="orgList" label="发放用户" v-if="ruleForm.userScopeType === '1'">
      <ykc-tree
        ref="treeUser"
        :data="usersData"
        :props="userProps"
        @check-change="handleUsersTree" />
    </ykc-form-item>

    <ykc-form-item prop="groupList" label="发放用户" v-if="ruleForm.userScopeType === '2'">
      <ykc-tree
        ref="treeUserGroup"
        :data="userGroupData"
        :props="userGroupProps"
        @check-change="handleUsersGroupTree" />
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { sessionGetItem } from '@/utils';
  import { getListNameById } from '@/utils/index';
  import { adsApi, uploadUtils, treeUtils } from '@/service/apis';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    components: {},
    data() {
      return {
        usersData: [],
        userGroupData: [],
        userProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        userGroupProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        launchTime: [],
        contractList: [],
        powerStationList: [],
        rangeValue: [],
        activeId: '',
        launchTerminalType2: [],
        launchTerminalType: [
          { id: '1', name: '客户端APP' },
          { id: '2', name: '微信小程序' },
        ],
        dictionary: {
          launchType: [
            { id: '1', name: '首页弹屏' },
            { id: '4', name: '电站列表' },
            { id: '2', name: '启动页' },
            // { id: '3', name: '电站详情' },
            { id: '8', name: '首页banner' },
            { id: '5', name: '开始充电' },
            { id: '6', name: '实时订单' },
            { id: '7', name: '个人中心' },
          ],
          userScopeType: [
            { id: '1', name: '按客户' },
            { id: '2', name: '按用户分组' },
            { id: '3', name: '全部' },
          ],

          skipType: [
            { id: '1', name: '不跳转' },
            { id: '2', name: '外部链接' },
          ],
          wechatSkipType: [
            { id: '1', name: '不跳转' },
            { id: '2', name: '外部链接' },
            { id: '3', name: '小程序' },
          ],
        },
        ruleForm: this.createRuleForm(),
        rules: {
          name: [
            { required: true, message: '请输入广告名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('广告名称'),
            },
          ],
          launchType: [{ required: true, message: '请选择广告位置', trigger: 'blur' }],
          image: [{ required: true, message: '请选择广告图片', trigger: 'blur' }],
          launchTerminalType: [{ required: true, message: '请选择投放终端', trigger: 'blur' }],
          appSkipType: [{ required: true, message: '请选择客户端跳转', trigger: 'blur' }],
          appSkipUrl: [{ required: true, message: '请输入客户端跳转地址', trigger: 'blur' }],
          wechatSkipType: [{ required: true, message: '请选择微信跳转', trigger: 'blur' }],
          wechatSkipUrl: [{ required: true, message: '请输入页面跳转路径', trigger: 'blur' }],
          wechatAppId: [{ required: true, message: '请输入小程序appId', trigger: 'blur' }],
          launchTime: [{ required: true, message: '请选择投放日期', trigger: 'blur' }],
          userScopeType: [{ required: true, message: '请选择用户维度', trigger: 'blur' }],
          orgList: [{ required: true, message: '请选择发放用户', trigger: 'blur' }],
          groupList: [{ required: true, message: '请选择发放用户', trigger: 'blur' }],
        },
      };
    },
    watch: {},
    computed: {
      formData() {
        const formData = {
          id: this.id || '',
          name: this.ruleForm.name,
          launchType: this.ruleForm.launchType,
          image: this.ruleForm.image, // 图片
          launchTerminalType: this.ruleForm.launchTerminalType,

          appSkipType: this.ruleForm.appSkipType,
          appSkipUrl: this.ruleForm.appSkipUrl,
          wechatAppId: this.ruleForm.wechatAppId,
          wechatSkipType: this.ruleForm.wechatSkipType,
          wechatSkipUrl: this.ruleForm.wechatSkipUrl,
          launchBeginTime: `${this.launchTime[0]} 00:00:00`,
          launchEndTime: `${this.launchTime[1]} 00:00:00`,
          launchTime: this.launchTime,
          userScopeType: this.ruleForm.userScopeType,
          orgList: this.ruleForm.orgList?.filter(item => item !== null),
          groupList: this.ruleForm.groupList?.filter(item => item !== null),
          //  list: this.ruleForm.list?.filter(item => item !== null), // 树ids
        };
        if (Number(formData.launchType === 2)) {
          delete formData.appSkipType;
          delete formData.appSkipUrl;
          delete formData.wechatSkipType;
          delete formData.wechatSkipUrl;
        }
        formData.list = this.ruleForm.list?.filter(item => item !== null);
        if (!formData.list) {
          formData.list = this.ruleForm.orgList?.filter(item => item !== null);
        }
        return formData;
      },
    },
    created() {
      if (this.isEdit) {
        adsApi
          .detail({
            id: this.id,
          })
          .then(res => {
            this.image = res.image;
            const startTime = res.launchBeginTime.substring(0, 10);
            const endTime = res.launchEndTime.substring(0, 10);
            this.launchTime = [startTime, endTime];
            res.launchTime = [startTime, endTime];
            this.launchTerminalType2 = res.launchTerminalType?.split(',') || [];
            this.$refs.adsImg.fileList = [{ url: res.image }];
            this.ruleForm = this.createRuleForm(res);
          });
      }
      const operatorId = sessionGetItem('accountId');
      const userTreeRequest = treeUtils.getAllOrgInfoGroupByTypeWithOutZdl({});
      const userTreeGroupRequest = treeUtils.operatorUserGroup({ operatorId });
      const requests = [userTreeRequest, userTreeGroupRequest];
      Promise.all(requests).then(([usersData, userGroup]) => {
        this.formatUserTreeData(usersData);
        this.formatUserGroupTreeData(userGroup);
        this.selectedTreesNodes(); // 填充选择内容
      });
    },
    methods: {
      getListNameById,
      createRuleForm(initValue = {}) {
        return {
          id: null,
          name: '',
          launchType: '',
          status: '',
          statusMean: '',
          image: '',
          launchTerminalType: '',
          launchTerminalTypeMean: '',
          appSkipType: '',
          appSkipTypeMean: '',
          appSkipUrl: '',
          wechatSkipType: null,
          wechatSkipTypeMean: null,
          wechatAppId: null,
          wechatSkipUrl: null,
          stationList: [],
          groupList: [],
          orgList: [],
          launchTime: '',
          launchBeginTime: '',
          launchEndTime: '',
          userScopeType: '3',
          userScopeTypeMean: '全部用户',
          createdName: 'admin',
          modifiedName: 'admin',
          list: null,
          ...initValue,
        };
      },
      uploadAd(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.image = res;
          });
        }
      },
      formatUserTreeData(data) {
        if (data) {
          this.usersData = [];
          if (data) {
            let id = 1000000;
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, users] of Object.entries(data)) {
              const user = {
                id: id++,
                name: key,
                subNodes: [],
                disabled: false,
              };
              users.forEach(item => {
                user.subNodes.push({
                  id: item.id,
                  name: item.name,
                });
              });
              this.usersData.push(user);
            }
          }
        }
      },
      formatUserGroupTreeData(data) {
        this.userGroupData = [];
        if (data?.length > 0) {
          data.forEach(item => {
            const userGroup = {
              id: item.id,
              name: item.groupName || '未知',
              disabled: false,
            };
            this.userGroupData.push(userGroup);
          });
        }
      },
      handleCheckedCitiesChange(e) {
        if (e.length > 1) {
          this.ruleForm.launchTerminalType = `${e[0]},${e[1]}`;
        } else if (e.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          this.ruleForm.launchTerminalType = e[0];
        } else {
          this.ruleForm.launchTerminalType = '';
        }
      },
      groupChange(e) {
        if (e === '1') {
          if (this.ruleForm.orgList?.length > 0) {
            this.$nextTick(() => {
              this.$refs.treeUser.setCheckedKeys(this.ruleForm.orgList, true);
            });
          }
        } else if (e === '2') {
          if (this.ruleForm.groupList?.length > 0) {
            this.$nextTick(() => {
              this.$refs.treeUserGroup.setCheckedKeys(this.ruleForm.groupList, true);
            });
          }
        }
      },
      handleUsersTree() {
        this.ruleForm.orgList = this.$refs.treeUser.getCheckedKeys(true) || [];
        this.ruleForm.list = this.$refs.treeUser.getCheckedKeys(true) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleUsersGroupTree() {
        this.ruleForm.groupList = this.$refs.treeUserGroup.getCheckedKeys(true) || [];
        this.ruleForm.list = this.$refs.treeUserGroup.getCheckedKeys(true) || [];
      },
      dateChange(e) {
        console.log(JSON.stringify(e));
        this.launchTime = e;
        this.ruleForm.launchTime = e;
      },
      selectedTreesNodes() {
        if (this.ruleForm?.id) {
          // 第一次加载的时候回显
          if (this.ruleForm.userScopeType === '1' && this.ruleForm.orgList?.length > 0) {
            this.$nextTick(() => {
              this.$refs.treeUser.setCheckedKeys(this.ruleForm.orgList, true);
            });
          } else if (this.ruleForm.userScopeType === '2' && this.ruleForm.groupList?.length > 0) {
            // 分组
            this.$nextTick(() => {
              this.$refs.treeUserGroup.setCheckedKeys(this.ruleForm.groupList, true);
            });
          }
        }
      },
      changeStation(val) {
        const item = this.powerStationList.find(o => o.stationId === val);
        this.ruleForm.operatorName = item?.operatorName ?? '';
        this.ruleForm.operatorAttribute = item?.operatorAttribute ?? '';
        this.ruleForm.stationStatusCode = item?.stationStatusCode ?? '';
      },
      submitForm() {
        console.log(JSON.stringify(this.formData));
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.isEdit) {
              adsApi.edit(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            } else {
              adsApi.add(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
