<!--
  网点标准采购价配置列表
-->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button type="plain" v-rbac="'ads:add'" @click="add">新增</ykc-button>
            <ykc-button type="plain" v-rbac="'ads:export'" @click="doExportFile">导出</ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <ImportData ref="addOrEdit" v-if="drawerType === 'import'"></ImportData>
        <AddOrEdit
          v-if="drawerType === 'edit'"
          :active="stepsIndex"
          ref="addOrEdit"
          :isEdit="isEdit"
          :id="id"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import ImportData from './ImportData.vue';
  import { adsApi } from '@/service/apis';
  import { offlineExport, code } from '@/utils';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
      ImportData,
    },
    data() {
      return {
        id: null,
        cityList: [],
        dictionary: {
          launchType: [
            { id: '1', name: '首页弹屏' },
            { id: '4', name: '电站列表' },
            { id: '2', name: '启动页' },
            { id: '8', name: '首页banner' },
            { id: '5', name: '开始充电' },
            { id: '6', name: '实时订单' },
            { id: '7', name: '个人中心' },
          ],
          userScopeType: [
            { id: '3', name: '全部' },
            { id: '1', name: '按客户' },
            { id: '2', name: '按用户分组' },
          ],
          adsStatus: [
            { id: '1', name: '未开始' },
            { id: '2', name: '启用中' },
            { id: '3', name: '已结束（手动结束）' },
            { id: '4', name: '已结束（自动结束）' },
          ],
        },
        drawerType: '',
        willEndCount: 0,
        listType: '0',
        stepsIndex: 0,
        maxIndex: 2,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.stepsIndex = 0;
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '广告名称', prop: 'name', minWidth: '200px' },
          { label: '广告位置', prop: 'launchTypeMean', minWidth: '200px' },
          { label: '投放开始时间', prop: 'launchBeginTime', minWidth: '200px' },
          { label: '投放结束时间', prop: 'launchEndTime', minWidth: '200px' },
          { label: '广告状态', prop: 'statusMean', minWidth: '200px' },
        ],
        tableTitle: '广告列表',

        tableOperateButtons: [
          {
            enabled: () => code('ads:stop'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.status === '3' || row.status === '4'}
                onClick={() => {
                  this.stopAds(row);
                }}>
                停用
              </ykc-button>
            ),
          },
          {
            enabled: () => code('ads:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.status !== '1'}
                onClick={() => {
                  this.isEdit = true;
                  this.id = row.id;
                  this.drawerType = 'edit';
                  this.showDrawer = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
          {
            enabled: () => code('ads:detail'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={false}
                onClick={() => {
                  this.detailLink(row);
                }}>
                详情
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        searchParams: {
          name: '',
          status: '',
          launchType: '',
          launchBeginTime: '',
          launchBeginStartTime: '',
          launchBeginEndTime: '',
          launchEndStartTime: '',
          launchEndEndTime: '',
          launchEndTime: '',
          userScopeType: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      this.searchTableList();
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          return '批量导入';
        }
        return `广告${this.isEdit ? '编辑' : '新增'}`;
      },
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '广告名称',
            placeholder: '请输入广告名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '广告状态',
            data: [all, ...(this.dictionary.adsStatus ?? [])],
          },
          {
            comName: 'YkcDropdown',
            key: 'launchType',
            label: '广告位置',
            data: [all, ...(this.dictionary.launchType ?? [])],
          },
          {
            comName: 'YkcDatePicker',
            key: 'launchBeginTime',
            label: '投放开始时间',
          },
          {
            comName: 'YkcDatePicker',
            key: 'launchEndTime',
            label: '投放结束时间',
          },

          {
            comName: 'YkcDropdown',
            key: 'userScopeType',
            label: '投放用户',
            data: [...(this.dictionary.userScopeType ?? [])],
          },
        ];
      },
    },
    methods: {
      detailLink(row) {
        this.$router.push({
          path: '/marketingCenter/clientAds/Detail',
          query: {
            id: row.id,
          },
        });
      },
      stopAds(row) {
        this.$dialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认停用当前广告吗?',
          onConfirm: done => {
            adsApi.stop({ id: row.id }).then(res => {
              console.log(res);
              done();
              this.$message({
                message: '停用成功',
                type: 'success',
              });
              this.searchTableList();
            });
          },
        });
      },
      add() {
        this.showAddDrawer = true;
        this.isEdit = false;
        this.id = '';
        this.drawerType = 'edit';
        this.showDrawer = true;
      },

      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          adsApi.queryForPage({
            ...this.pageInfo,
            name: this.searchParams.name,
            status: this.searchParams.status,
            launchType: this.searchParams.launchType,
            userScopeType: this.searchParams.userScopeType,
            launchBeginStartTime: this.formatDate(this.searchParams.launchBeginTime[0]),
            launchBeginEndTime: this.formatDate(this.searchParams.launchBeginTime[1]),
            launchEndStartTime: this.formatDate(this.searchParams.launchEndTime[0]),
            launchEndEndTime: this.formatDate(this.searchParams.launchEndTime[1]),
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'app_advertisement_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.drawerType = 'import';
        this.isEdit = true;
        this.showDrawer = true;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      // 格式化时间
      formatDate(date) {
        let result = '';
        if (date) {
          result = `${date} 00:00:00`;
        }
        return result;
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
